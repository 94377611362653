/*
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-03-11 14:29:25
 * @LastEditors: hutian
 * @LastEditTime: 2021-05-19 15:10:15
 */
/**
* api接口统一管理
*/
import { axios } from '@/utils/request'
const qs = require('qs');


export const listOrderInfo = params => axios({
    url: '/api/order/dealer/dealerOrderInfo/list',
    method: 'get',
    params,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    },
    paramsSerializer: function(params) {
      return qs.stringify(params, {arrayFormat: 'repeat'})
    }
})

export const addOrderInfo = params => axios({
    url: '/api/order/order/orderInfo/add',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const editOrderInfo = params => axios({
    url: '/api/order/order/orderInfo/edit',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const delOrderInfo = params => axios({
    url:'/api/order/order/orderInfo/del/' + params,
    method: 'post',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const rollbackStatus = params => axios({
  url:'/api/product/system/info/rollbackStatus/' + params,
  method: 'post',
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
  }
})

export const selectByIdOrderInfo = params => axios({
    url: '/api/order/order/orderInfo/selectById/' + params,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})
export const selectByIdOrderShipInfo = params => axios({
  url: '/api/order/order/orderInfo/selectById/' + params,
  method: 'get',
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
  }
})
